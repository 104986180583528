import React, { useState } from 'react';
import { Dropdown, DropdownProps } from 'reactstrap';

interface Props extends DropdownProps {
  defaultOpen?: boolean;
}

const HoverDropdown = (props: Props): JSX.Element => {
  const { defaultOpen, ...toPassProps } = props;

  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <Dropdown
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...toPassProps}
      isOpen={isOpen}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      toggle={() => setIsOpen(!isOpen)}
    />
  );
};

export default HoverDropdown;
