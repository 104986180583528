/* eslint-disable react/no-array-index-key */
import React from 'react';

import colors from '../../styles/colors.scss';
import getColour from '../../utils/helper.utils';
import styles from './item-grid.module.scss';

interface Props {
  title: string;
  items?: {
    label: string;
    icon: string;
  }[][];
  circles?: {
    label?: string;
    circleBackgroundColour?: string;
    circleTextColour?: string;
    circleText?: string;
  }[];
}

const ItemGrid = (props: Props): JSX.Element => {
  const { title, items, circles } = props;

  const renderCircle = ({
    size = 140,
    ringWidth = 3,
    circleBackgroundColour = colors.brandPrimary,
    borderColour = colors.white,
    circleText = '',
    textColour = colors.white,
  }) => (
    <div
      className={styles.outerCircle}
      style={{
        width: size,
        height: size,
        backgroundColor: getColour(circleBackgroundColour),
      }}
    >
      <div
        className={styles.innerCircle}
        style={{
          width: size - ringWidth * 3,
          height: size - ringWidth * 3,
          borderWidth: ringWidth,
          borderColor: getColour(borderColour),
        }}
      >
        <span
          className={styles.circleText}
          style={{ color: getColour(textColour) }}
        >
          {circleText}
        </span>
      </div>
    </div>
  );

  return (
    <div className={styles.ethos}>
      <div className={styles.ethosContent}>
        <div className={styles.copy}>
          <h1>{title}</h1>
        </div>
        <div className={styles.cards}>
          {items?.map((row, index) => (
            <div key={`row-${index}`} className={styles.row}>
              {row.map((card, cardIndex) => (
                <div key={`card-${cardIndex}`} className={styles.card}>
                  {card.icon ? <img src={card.icon} alt={card.label} /> : null}
                  <div className={styles.label}>{card.label}</div>
                </div>
              ))}
            </div>
          ))}
          {circles?.map((circle, index) => (
            <div key={`row-${index}`} className={styles.row}>
              <div key={`card-${index}`} className={styles.circleCard}>
                {renderCircle({
                  circleBackgroundColour: circle.circleBackgroundColour,
                  circleText: circle.circleText,
                })}
                <div className={styles.label}>
                  <p>{circle.label}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemGrid;
