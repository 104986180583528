import colors from '../styles/colors.scss';
import theme from '../styles/theme.scss';

const getColour = (colour?: string) => {
  if (theme[colour]) {
    return theme[colour];
  }
  if (colors[colour]) {
    return colors[colour];
  }
  return colour;
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export default getColour;
