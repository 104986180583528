import React from 'react';

import Countdown from '../components/countdown/Countdown';
import Page from '../components/page/Page';
import sandpitImage from '../images/sandpit.jpeg';

interface Props {
  errorCode: number;
}

const Error = (props: Props): JSX.Element => {
  const { errorCode } = props;

  const getErrorText = () => {
    switch (errorCode) {
      case 404:
        return (
          <p>
            You might have mistyped the address, or we might have moved the
            content. Either way, you could try{' '}
            <a href="https://www.google.com.au">searching again</a> or{' '}
            <a href="/contact">dropping us a line</a>.
          </p>
        );
      default:
        return <p>Something has gone wrong :(</p>;
    }
  };

  return (
    <Page title={`${errorCode}`}>
      <div style={{ minHeight: '100vh' }}>
        <Countdown
          fill
          index={errorCode}
          title=""
          subtitle="The page you were looking for isn't here. We must be out having
          fun in the sandpit."
          content={getErrorText()}
          image={sandpitImage}
        />
      </div>
    </Page>
  );
};

export default Error;
