import React from 'react';
import { useResizeDetector } from 'react-resize-detector';

import Countdown from '../components/countdown/Countdown';
import Page from '../components/page/Page';
import galleryImage1 from '../images/DSC_1390.jpeg';
import data from '../site-data.json';
import { getWindowDimensions } from '../utils/helper.utils';
import styles from './contact-page.module.scss';

const ContactPage = (): JSX.Element => {
  const { height, ref } = useResizeDetector();
  const { width: innerWidth } = getWindowDimensions();
  const newHeight = innerWidth > 992 ? height + 235 : 500;

  return (
    <Page title={`${data.siteName} - Contact Us`}>
      <Countdown
        imageLeft
        image={galleryImage1}
        title="Come and say hello!"
        content={
          <>
            <p>
              We are looking forward to welcoming you to Queanbeyan Childcare
              Centre soon.
            </p>
            <p>
              If you have any questions, contact the Director via email or
              phone.
            </p>
            <p>
              We will respond within 24 hours during business hours from Monday
              to Friday.
            </p>
            <div className={styles.ctaCentered}>
              <a
                href="https://ccs-queanbeyanchildcare.kinderm8.com.au/enquiry-form"
                target="_blank"
                className={styles.ctaLink}
                rel="noopener noreferrer"
              >
                <button className={styles.solidButton} type="button">
                  Enquire Now
                </button>
              </a>
            </div>
          </>
        }
        backgroundColour="accent4"
      />
      <Countdown
        renderImage={
          <div className={styles.googleMapWrapper}>
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.502223275661!2d149.21598331578545!3d-35.34318060450537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b164e92a652c467%3A0x1ce653a8dad3b59d!2sCreate%20Imagine%20Learn%20Queanbeyan!5e0!3m2!1sen!2sau!4v1667611020161!5m2!1sen!2sau"
              width="100%"
              height={newHeight}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        }
        content={
          <div ref={ref}>
            <div className={styles.item}>
              <h2 className={styles.titleText}>ADDRESS</h2>
              <a
                href={data.addressGoogleMapsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.itemLink}
              >
                <p className={styles.bodyText}>{data.address}</p>
              </a>
            </div>
            <div className={styles.item}>
              <h2 className={styles.titleText}>TELEPHONE</h2>
              <a href={`tel:${data.phone}`} className={styles.itemLink}>
                <p className={styles.bodyText}>{data.phone}</p>
              </a>
            </div>
            <div className={styles.item}>
              <h2 className={styles.titleText}>OPERATING HOURS</h2>
              <p className={styles.bodyText}>{data.openingHours}</p>
            </div>
            <div className={styles.item}>
              <h2 className={styles.titleText}>EMAIL</h2>
              <a
                href={`mailto:${data.email}?subject=Website Enquiry`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.itemLink}
              >
                <p className={styles.bodyText}>{data.email}</p>
              </a>
            </div>
          </div>
        }
        backgroundColour="accent4"
      />
    </Page>
  );
};

export default ContactPage;
