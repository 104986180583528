/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';

import getColour from '../../utils/helper.utils';
import styles from './message.module.scss';

interface Props {
  title: string;
  subtitle?: string;
  body?: string[];
  renderBody?: JSX.Element;
  cta?: {
    href: string;
    buttonStyle: string;
    buttonBackgroundColour: string;
    buttonTextColour: string;
    buttonText: string;
  };
  backgroundColour?: string;
}

const Message = (props: Props): JSX.Element => {
  const {
    title,
    subtitle,
    body,
    renderBody,
    cta,
    backgroundColour = '#fff',
  } = props;

  return (
    <div
      className={styles.message}
      style={{ backgroundColor: getColour(backgroundColour) }}
    >
      <div className={styles.messageContent}>
        {title && <h1 className={styles.messageTitle}>{title}</h1>}
        {subtitle && <h2 className={styles.messageSubtitle}>{subtitle}</h2>}
        {renderBody ||
          (body && (
            <div className={styles.messageBody}>
              {body.map((text, index) => (
                <p
                  key={`message-text-${index}`}
                  className={styles.messageBodyText}
                >
                  {text}
                </p>
              ))}
            </div>
          ))}
        {cta && (
          <div className={styles.ctaLinkContainer}>
            <Link to={cta.href} className={styles.ctaLink}>
              <button
                className={
                  cta.buttonStyle === 'solid'
                    ? styles.solidButton
                    : styles.textButton
                }
                style={{
                  backgroundColor: getColour(cta.buttonBackgroundColour),
                  borderColor: getColour(cta.buttonBackgroundColour),
                  color: getColour(cta.buttonTextColour),
                }}
                type="button"
              >
                {cta.buttonText}
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
