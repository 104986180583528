import React, { CSSProperties } from 'react';

import styles from './page-header.module.scss';

interface Props {
  title: string;
  subtitle?: string;
  image?: string;
  containerClassName?: string;
  containerStyle?: CSSProperties;
}

const PageHeader = (props: Props): JSX.Element => {
  const {
    title,
    subtitle,
    image,
    containerClassName = '',
    containerStyle = {},
  } = props;

  return (
    <div
      className={`${styles.pageHeader} ${containerClassName}`}
      style={{ backgroundImage: `url(${image})`, ...containerStyle }}
    >
      <div className={styles.titleContainer}>
        <h1>{title}</h1>
        {subtitle && <h2>{subtitle}</h2>}
      </div>
    </div>
  );
};

export default PageHeader;
