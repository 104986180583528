/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';

import getColour from '../../utils/helper.utils';
import ItemGrid from '../item-grid/ItemGrid';
import styles from './countdown.module.scss';

interface Props {
  index?: number;
  title?: string;
  subtitle?: string;
  backgroundColour?: string;
  content?: JSX.Element;
  image?: string;
  imageLeft?: boolean;
  renderImage?: JSX.Element;
  fill?: boolean;
  itemGrid?: any;
  cta?: {
    buttonText: string;
    buttonColour: string;
    buttonTextColour: string;
    buttonStyle?: string;
    href: string;
  };
}

const Countdown = (props: Props): JSX.Element => {
  const {
    index,
    title,
    subtitle,
    backgroundColour,
    content,
    image,
    imageLeft,
    renderImage,
    itemGrid,
    fill,
    cta,
  } = props;
  return (
    <div className={`${styles.countdown} ${fill ? styles.fill : ''}`}>
      <div
        className={`${styles.countdownContent} ${
          imageLeft ? styles.imageLeft : ''
        }`}
        style={{ backgroundColor: getColour(backgroundColour) }}
      >
        {itemGrid ? (
          <div className={styles.itemGrid}>
            <ItemGrid
              title={itemGrid.title}
              items={itemGrid.items}
              circles={itemGrid.circles}
            />
          </div>
        ) : (
          <div className={styles.copy}>
            {index && <div className={styles.index}>{index}</div>}
            <h1>{title}</h1>
            {subtitle?.split('\\n').map((line, lineIndex) => (
              <h3 key={`subtitle-${lineIndex}`}>{line}</h3>
            ))}
            {content && <div className={styles.content}>{content}</div>}
            {cta && (
              <div>
                <Link to={cta.href} className={styles.ctaLink}>
                  <button
                    className={
                      cta.buttonStyle === 'solid'
                        ? styles.solidButton
                        : styles.textButton
                    }
                    type="button"
                  >
                    {cta.buttonText}
                  </button>
                </Link>
              </div>
            )}
          </div>
        )}
        {renderImage || (
          <div className={styles.image}>
            {image ? <img src={image} alt={title} /> : 'Image here'}
          </div>
        )}
      </div>
    </div>
  );
};

export default Countdown;
