/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler,
    NavItem, NavLink
} from 'reactstrap';

import Logo from '../../images/logo.png';
import data from '../../site-data.json';
import HoverDropdown from '../hover-dropdown/HoverDropdown';
import styles from './navbar.module.scss';

interface Props {
  links?: {
    href: string;
    label?: string;
    buttonText?: string;
    buttonTextColour?: string;
    buttonBackgroundColour: string;
  }[];
}

const NavbarComponent = (props: Props): JSX.Element => {
  const { links } = props;
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const location = useLocation();

  const toggleNav = () => setIsNavOpen((_isNavOpen) => !_isNavOpen);

  return (
    <Navbar color="light" light expand="lg" className={styles.navbar}>
      <NavbarBrand href="/" className={styles.desktopNavbarBrand}>
        <div className={styles.icon}>
          <img src={Logo} alt="group" />
        </div>
      </NavbarBrand>
      <NavbarToggler right="true" onClick={toggleNav} className="ms-auto" />
      <Collapse isOpen={isNavOpen} navbar>
        <Nav className={`ml-auto ${styles.navbar}`} navbar>
          <div className={styles.linksContainer}>
            {(links || data.navbar.links)?.map((link, index) => {
              if (link.buttonText) {
                return (
                  <div
                    key={`nav-item-${index}`}
                    className={styles.ctaLinkContainer}
                  >
                    <Link to={link.href} className={styles.ctaLink}>
                      <button
                        className={
                          link.buttonStyle === 'solid'
                            ? styles.solidButton
                            : styles.textButton
                        }
                        style={{
                          backgroundColor: link.buttonBackgroundColour,
                          borderColor: link.buttonBackgroundColour,
                          color: link.buttonTextColour,
                        }}
                        type="button"
                      >
                        {link.buttonText}
                      </button>
                    </Link>
                    <div className={styles.activeBar} />
                  </div>
                );
              }
              if (link.links?.length)
                return (
                  <HoverDropdown
                    key={`nav-item-${index}`}
                    nav
                    inNavbar
                    active={link.links.some(
                      (dropdownLink) => dropdownLink.href === location.pathname,
                    )}
                  >
                    <DropdownToggle nav caret className={styles.navDropdown}>
                      {link.label}
                    </DropdownToggle>
                    <DropdownMenu end>
                      {link.links.map((menuLink, menuLinkIndex) => (
                        <NavLink
                          key={`menu-item-${index}-${menuLinkIndex}`}
                          href={menuLink.href}
                          className={styles.menuLink}
                          active={menuLink.href === location.pathname}
                        >
                          <DropdownItem
                            divider={menuLink.divider}
                            className={styles.navItem}
                          >
                            {menuLink.label}
                          </DropdownItem>
                        </NavLink>
                      ))}
                    </DropdownMenu>
                  </HoverDropdown>
                );

              return (
                <NavItem
                  key={`nav-item-${index}`}
                  className={styles.navItem}
                  active={link.href === location.pathname}
                >
                  <NavLink href={link.href} className={styles.navLink}>
                    {link.label}
                  </NavLink>
                </NavItem>
              );
            })}
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
