import './styles/theme.scss';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import About from './pages/About';
import ContactPage from './pages/ContactPage';
import Error from './pages/Error';
import Home from './pages/Home';
import ProgramEarlyLearning from './pages/programs/ProgramEarlyLearning';
import ProgramPreschool from './pages/programs/ProgramPreschool';
import ProgramToddlerLearning from './pages/programs/ProgramToddlerLearning';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize('G-N0JK2ZV7PQ');

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/programs/early-learning"
          element={<ProgramEarlyLearning />}
        />
        <Route
          path="/programs/toddler-learning"
          element={<ProgramToddlerLearning />}
        />
        <Route path="/programs/preschool" element={<ProgramPreschool />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<Error errorCode={404} />} />
      </Routes>
    </>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
